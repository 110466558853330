exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-byop-js": () => import("./../../../src/pages/byop.js" /* webpackChunkName: "component---src-pages-byop-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-patents-js": () => import("./../../../src/pages/patents.js" /* webpackChunkName: "component---src-pages-patents-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-suppliers-js": () => import("./../../../src/pages/suppliers.js" /* webpackChunkName: "component---src-pages-suppliers-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-templates-gated-resource-page-js": () => import("./../../../src/templates/gatedResourcePage.js" /* webpackChunkName: "component---src-templates-gated-resource-page-js" */),
  "component---src-templates-legal-page-tsx": () => import("./../../../src/templates/legalPage.tsx" /* webpackChunkName: "component---src-templates-legal-page-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/pageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-press-release-page-js": () => import("./../../../src/templates/pressReleasePage.js" /* webpackChunkName: "component---src-templates-press-release-page-js" */)
}

